$screen-xl-min: 1300px; // Large tablets and desktops
$screen-lg-min: 1024px; // Tablets and small desktops
// $screen-xm-min: 920px; // Tablets and small desktops
$screen-md-min: 768px; // Small tablets (portrait view)
$screen-sm-min: 576px; // Small tablets and large smartphones (landscape view)
$screen-xs-min: 500px; // Tiny phones


// XLarge devices
@mixin xl {
    @media (max-width: #{$screen-xl-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-min}) {
        @content;
    }
}

// // Medium-large devices
// @mixin xm {
//     @media (max-width: #{$screen-xm-min}) {
//         @content;
//     }
// }

// Med devices
@mixin md {
    @media (max-width: #{$screen-md-min}) {
        @content;
    }
}

// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-min}) {
        @content;
    }
}

// XSmall devices
@mixin xs {
    @media (max-width: #{$screen-xs-min}) {
        @content;
    }
}

::-moz-selection {
    background: transparent; 
    text-shadow: none;
}

::selection {
    background: transparent;
    text-shadow: none;
}

h2,
h3, p {
    margin: 0;
}

a, button {
    &:hover {
        cursor: pointer;
    }
}
section#hero {
    background-image: url('/img/Fuul_Digital_LandingPage_BackgroundGif_4.gif');
    background-repeat: no-repeat;
    background-size: cover;
    height: 1000px;
    position: relative;
    top: 99px;
    animation: reduceHeightGif 1s ease-in both;
    animation-delay: 1.5s;

    @include md {
        height: 500px;
        animation: reduceHeightGif-md 1s ease-in both;
        animation-delay: 1.5s;
    }

    @include xs {
        top: 71px;
        height: 300px;
        animation: reduceHeightGif-sm 1s ease-in both;
        animation-delay: 1.5s;
    }

    .animate-reveal {
        font-family: 'futura-pt',  sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 98px;
        color: #FFFFFF;
        letter-spacing: -1px;
        text-align: left;
        line-height: 97px;
        animation: scale 2.5s cubic-bezier(0, 1, 0.5, 1) 1 normal forwards;
        text-transform: uppercase;
        transition: height 0ms 0ms,
        opacity 600ms 0ms;

      @include xs {
        font-size: 55px;
        letter-spacing: -1px;
        line-height: 50px;
        width: 80%;
        margin: 0 auto;
      }

    }

    .hero-container {
        max-width: 1000px;
        margin: 0 auto;
        padding-top: 6rem;
        @include xl {
            width: 85%;
        }
         @include xs {
             padding-top: 3rem;
             width: 100%;
         }

        
    }


    .animate-first {
        -webkit-animation-delay: 0;
        -moz-animation-delay: 0;
        -o-animation-delay: 0;
        animation-delay: 0;
    }

    .animate-second {
        -webkit-animation-delay: 500ms;
        -moz-animation-delay: 500ms;
        -o-animation-delay: 500ms;
        animation-delay: 500ms;
    }

    .animate-third {
        -webkit-animation-delay: 1s;
        -moz-animation-delay: 1s;
        -o-animation-delay: 1s;
        animation-delay: 1s;
    }

    @keyframes reveal {
        0% {
            opacity: 0;
            -webkit-transform: translateY(100%);
            -moz-transform: translateY(100%);
            -o-transform: translateY(100%);
            transform: translateY(100%);
        }

        100% {
            opacity: 1;
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0);
        }
    }

    .fade-in {
        // animation: fade-in 1s cubic-bezier(0.645, 0.045, 0.355, 1.000) both;
        
    }

    .fade-out {
        animation: fade-out 1.5s linear both;
        animation-delay: 1s;

        @include xs {
            animation: fade-out-sm 1.5s linear both;
            animation-delay: .85s;
        }
        
        
        // transition: 1s;
    }

    span.quotes {
        animation: fade-out-width 1.5s linear both;
        animation-delay: 1s;
        
        
        transition:width 0.25s;
        -webkit-transition:width .25s;
        -moz-transition: width 0.25s;
        display:inline;
        display:inline;

        &.first {
            width: 100%;
            right: 40px;
            position: absolute;

            @include xs {
                right: 25px;
                position: absolute;
                width: 100%;
            }
        }
    }
    @keyframes scale {
        from {
            transform: scale(0);
        }

        to {
            transform: scale(1);
        }
    }

    // .last-fade-out {
    //     // transform: scaleY(0);
    //     // animation-delay: .75s;
    //     animation: fade-in 1.5s linear both;
    //     animation-delay: 1s;
    //     transition: 1s;
    // }
    /**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
    @keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
    /**
    * ----------------------------------------
    * animation fade-out
    * ----------------------------------------
    */
    @keyframes fade-out {
        0% {
            opacity: .5;
            height: 100px;
            
        }
        25% {
            opacity: .5;
            height: 100px;

        }
        50% {
            opacity: .3;
            height: 100px;

        }
        75% {
            opacity: .1;
            height: 50px;

        }

        100% {
            opacity: 0;
            height: 0;
        }
        
    }
    @keyframes fade-out-width {
        0% {
            opacity: 1;
            // width: 100%;
      
        }

        25% {
            opacity: .8;
            // width: 37px;
            
        }

        50% {
            opacity: .5;
            // width: 37px;
          

        }

        75% {
            opacity: .1;
            // width: 17px;
          

        }

        100% {
            opacity: 0;
            // width: 0%;
          
        }

    }
    @keyframes reduceHeightGif {
        0% {
            height: 1000px;

        }

        100% {
            height: 800px;
        }
    }

    @keyframes reduceHeightGif-md {
        0% {
            height: 850px;

        }

        100% {
            height: 650px;
        }
    }
    @keyframes reduceHeightGif-sm{
        0% {
            height: 400px;

        }

        100% {
            height: 300px;
        }
    }
      @keyframes fade-out-sm {
          0% {
              opacity: .5;
              height: 50px;

          }

          25% {
              opacity: .5;
              height: 50px;

          }

          50% {
              opacity: .3;
              height: 50px;

          }

          75% {
              opacity: .1;
              height: 25px;

          }

          100% {
              opacity: 0;
              height: 0;
          }

      }

    

}

header {
    height: 99px;
    position: fixed;
    z-index: 5;
    background: white;
    width: 100%;
    display: flex;
    align-items: center;

      @include xs {
         display: block;
         height: 71px;
      }
      
      .logo {


        picture {
            margin-left: 5rem;
            transition: all .3s;

            &:hover {
                opacity: .7;
            }

            @include md {
                margin-left: 3rem;

            }
            @include xs {
                margin-left: 1.5rem;
                float: left;
                margin-top: 23px;
                width: 54px;
            }
        }
      }

    .nav-flex {
        width: 85%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    nav ul {
        display: flex;
        list-style-type: none;
        padding-left: 0;
        @include xs {
              display: block;
        }

        li {
            font-family: 'futura-pt', sans-serif;
            font-size: 10px;
            color: #2A2929;
            letter-spacing: 4.08px;
            text-align: right;
            line-height: 15px;
            font-weight: 400;
            text-transform: uppercase;
            margin: 0 1rem;
              @include xs {
                  text-align: center;
                    margin: 1.5rem 0;
              }

            a {
                text-decoration: none;
                color: #2A2929;
                line-height: 25px;
                @include xs {
                    display: table;
                    line-height: 20px;
                    margin: 0 auto;
                }

               

                &.active {

                    // background-image: linear-gradient(180deg, rgba(255, 94, 230, 0), rgba(255, 94, 230, 0) 80%, #ff5ee6 80.1%, #ff5ee6 87%, rgba(255, 94, 230, 0) 88.1%, rgba(255, 94, 230, 0));
                    // padding-bottom: 6px;

                    &:after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 4px;
                        background: #FF5EE6;
                        transition: width .3s;

                        // @include md {
                        //     transition: 0;
                        //     width: 100%;
                        //     content: none;
                        //     height: 0;
                        // }

                        @include xs {
                            height: 4px;

                        }
                    }
                }

                    &:after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 4px;
                        background: #FF5EE6;
                        transition: width .3s;

                        // @include md {
                        //     transition: 0;
                        //     width: 100%;
                        //     content: none;
                        //     height: 0;
                        // }

                        // @include xs {
                        //     transition: 0;
                        //     width: 100%;
                        //     content: none;
                        //     height: 0;
                        // }
                    }

                    &:hover:after {
                        width: 100%;
                        //transition: width .3s;
                    }
            }
        }
    }
}

footer {
  background: #2A2929;
    @include xs {
        padding: 2rem 0 5rem 0
    }
  
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    align-items: center;

     @include xs {
         display: block;

     }
        .logo {
            margin: 3rem 0;
            // margin: 3rem;

            img {
            transition: all .3s;

            &:hover {
                opacity: .7;
            }

                @include xs {
                    margin: 0 auto;
                    display: block;

                }
            }
            @include xs {
               margin: 0;
                margin-top: 3rem;
            }
        }

  }


}

section#video {
    top: -325px;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    @include xl {
        width: 85%;
    }

    @include md {
       top: -180px;

    }
    @include xs {
        top: 0;
        width: 100%;
    }


     a.skip-to-video {
         margin: 0 auto;
         text-align: center;
         /* width: 100%; */
         margin: 0 auto;
         display: flex;
         /* text-align: center; */
         padding-bottom: 4rem;
        //  position: absolute;
         width: 100%;
         justify-content: center;
         max-width: 1000px;

         @include xs {
             padding-bottom: 2rem;
         }

     }

    video {
        max-width: 1200px;
        width: 100%;
        height: 100%;
        background: transparent url('/img/fuul_creep_group.jpg') no-repeat 0 0;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

         @include xs {
             max-height: 100%;
             background-image: url('/img/fuul_creep_group.jpg');
             width: 100%;
             background-size:cover;
         }
    }
}

section#columns {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8rem;
    margin-top: -8rem;
    @include xl {
        width: 85%;
    }
    @include md {
        display: block;
        margin-top: -5.5rem;
        margin-bottom: 2.5rem;
    }

    @include xs {
       display: block;
       margin-top: 2.5rem;
       margin-bottom: 2.5rem;
       width: 100%;
    }
    .columns-left {
        // width: 40%;
        width: 48%;
        border-right: 10px solid #FF5EE6;
        height: 300px;
        @include md {
            height: auto;
            border-right: none;
            width: 100%;
            // margin: 0 auto;
        }
         @include xs {
             height: auto;
             border-right: none;
             width: 80%;
             margin: 0 auto;
         }
    }

    .columns-right {
        width: 40%;
        @include md {
            width: 100%;
            margin: 0 auto;
            margin-top: 1rem;
            padding-bottom: 3rem;
        }
           @include xs {
               width: 80%;
               margin: 0 auto;
               margin-top: 1rem;
           }
    }


    h2 {
        font-family: 'futura-pt',
        sans-serif;
        font-size: 75px;
        color: #1E268B;
        letter-spacing: -1px;
        line-height: 90px;
        font-weight: 600;
        width: 85%;

        @include md {
            margin-bottom: 3rem;
            line-height: 70px;
        }

         @include xs {
            font-size: 37px;
            letter-spacing: -1.09px;
            line-height: 44px;
            width: 90%;
         }
    }

    p {
        font-family: 'futura-pt',
        sans-serif;
        font-size: 22px;
        color: #4A4A4A;
        text-align: left;
        line-height: 40px;
        font-weight: 400;
        @include xs {
            font-size: 14px;
            line-height: 25px;
        }


        

        span {
            font-weight: 600;
        }
    }
}

section#facts {
   background: #F6F6F6;
   padding-top: 8rem;

   @include md {
       padding-top: 5rem;
   }

   
    @include xs {
        padding-top: 5rem;
    }


    .facts-container {
        max-width: 1200px;
        margin: 0 auto;
        @include xl {
        width: 85%;
        }

        @include xs {
            width: 80%;
        }
            
        
        

         h2 {
            font-family: 'futura-pt',
            sans-serif;
            font-size: 75px;
            color: #FF5EE6;
            letter-spacing: 0;
            line-height: 70px;
            font-weight: 600;
            margin-bottom: 5rem;

             @include xs {
                font-size: 37px;
                letter-spacing: -1px;
                line-height: 44px;
                margin-bottom: 2rem;
                width: 90%;
             }


         }

         hr {
             margin: 0;
             height: 10px;
             width: 120px;
             background: #FF5EE6;
             margin-top: 6rem;

             @include xs {
                 margin-top: 3rem;
                 width: 100%;
             }
         }

         .facts-grid {
             display: flex;
             flex-wrap: wrap;
             justify-content: space-between;

            @include lg {
                // justify-content: center;
            }
            picture {
                width: 31%;
                margin-bottom: 1.5rem;
                 @include lg {
                     width: 48%;
                 }


                 @include xs {
                     width: 100%;
                     height: auto;
                 }
                img {
                    width: 100%;
                }
            }
            //  img {
            //      width: 31%;
            //      margin-bottom: 1.5rem;
            //     @include lg {
            //         width: 48%;
            //     }

                 
            //      @include xs {
            //         width: 100%;
            //         height: auto;
            //      }
            //  }
         }
    }
   


}
section#story {
    background: #F6F6F6;
    padding-top: 7rem;
    box-shadow: 0 10px 12px -5px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 2;

    @include xs {
        padding-top: 5rem;
    }
    

    .story-container {
        max-width: 1200px;
        margin: 0 auto;
        width: 80%;
        // padding-top: 5rem;

        h2 {
            font-family: 'futura-pt',
                sans-serif;
            font-size: 75px;
            color: #1E268B;
            letter-spacing: 0;
            font-weight: 600;
            line-height: 70px;
            margin-top: 0;
            margin-bottom: 6rem;
            @include md {
                margin-bottom: 4rem;
            }

            @include xs {
                font-size: 37px;
                letter-spacing: -1px;
                line-height: 44px;
                margin-bottom: 2rem;
                width: 68%;
            }

        }

        article {
            width: 50%;
            margin-bottom: 5rem;
            display: grid;

            @include xs {
                width: 100%;
                margin-bottom: 3rem;

            }

            img {
                display: block;
                margin: 0 auto;
                padding: 1.5rem 0;
                @include xs {
                    padding: 1rem 0;

                }
            }

            h3 {
                font-family: 'eb-garamond',
                    serif;
                font-weight: 400;
                font-style: italic;
                font-size: 34px;
                color: #4A4A4A;
                letter-spacing: 0;
                text-align: center;
                line-height: 44px;
                width: 90%;
                margin: 0 auto;

                @include xs {
                    font-size: 26px;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 34px;
                }

                
            }

            a {
                margin: 0 auto;
                display: block;
                text-align: center;
                margin: auto 0;
                margin-bottom: 0;

                 @include md {
                    margin: 0;
                    display: block;
                    text-align: center;
                    margin-top: auto;
                 }



                &:hover {

                    button {
                        border: 1px solid #FF5EE6;
                        background: #FF5EE6;
                        color: #F6F6F6;

                    }

                }

                button {
                    font-family: 'futura-pt',
                        sans-serif;
                    font-size: 12px;
                    color: #1E268B;
                    letter-spacing: 2.4px;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: 400;
                    border: 1px solid #FF5EE6;
                    padding: .75rem 2rem;
                    transition: all .3s;
                    background: #F6F6F6;

                     @include xs {
                         font-size: 10px;
                         letter-spacing: 2px;
                         text-align: center;
                         width: 268px;

                     }

                }
            }



        }

        .story-grid {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @include xs {
                display: block;
            }
        }

        div#accordion-hide.story-grid {
            display: none;
            transition: all 0.4s ease;
        }
        .accordion-view-less {
            transform: rotate(180deg);
        }
        .accordion-view-less-flip {
            transform: rotate(180deg);
        }

        a.accordion-view-more {
            font-family: 'futura-pt',
                sans-serif;
            font-size: 14px;
            color: #FF5EE6;
            letter-spacing: 4.57px;
            text-align: center;
            line-height: 26px;
            font-weight: 400;
            text-transform: uppercase;
            margin: 0 auto;
            display: block;
            padding-bottom: 3rem;
            transition: all 0.4s ease;

            img {
                display: block;
                margin: 0 auto;
                padding: 1rem 0;
                transition: all 0.4s ease;
            }
        }

    }
    
}

section#take-action {
    max-width: 1200px;
    margin: 0 auto; 
    padding-bottom: 4rem;
    padding-top: 6rem;
    margin-top: -6rem;
    @include md {
        width: 80%;
        margin: 0 auto;
    }

      @include xs {
        width: 80%;
        margin: 0 auto;
        margin-top: -3rem;
        padding-top: 3rem;
      }

    

    .columns {
        display: flex;
        align-items: center;
        @include xl {
            width: 85%;
            margin: 0 auto;
        }
        @include md {
            display: block;
            margin-bottom: -14rem;
        }

        @include xs {
            display: block;
            margin-bottom: -14rem;
            width: 100%;
        }

        .right {
            z-index: 1;
            @include md {
                position: relative;
                top: -280px;
                z-index: -1;
            }
            @include xs {
                position: relative;
            top: -225px;
                z-index: -1;
            }

                
            img {
                position: relative;
                top: -20px;
                z-index: 3;
                top: -2rem;

                @include xl {
                    max-width: 450px;
                }

                @include lg {
                    max-width: 400px;
                }

                @include md {
                    width: 100%;
                    max-height: 100%;
                    top: 0;
                }


                 @include xs {
                     width: 100%;
                     max-height: 100%;
                     top: 0;
                 }
            }
        }
    }

    .number-grid {
        display: flex;
        justify-content: space-between;

         @include xl {
             width: 85%;
             margin: 0 auto;
         }
         @include lg {
             flex-wrap: wrap;
            justify-content: center;
         }
          @include md {
              display: block;
          }

         @include xs {
            display: block;
            width: 100%;
         }

        .number-grid-item {
            width: 30%;
            display: grid;

            @include lg {
                width: 51%;
                margin-bottom: 4rem;
                grid-gap: .5rem;
            }
            @include md {
                width: 100%;
                margin-bottom: 2rem;

            }

            @include xs {
                width: 100%;
                margin-bottom: 2rem;
               
            }
        }
    }

    h2 {
        font-family: 'futura-pt',
        sans-serif;
        font-size: 75px;
        color: #1E268B;
        letter-spacing: 0;
        line-height: 70px;
        font-weight: 600;
        margin-bottom: 3rem;

        @include md {
            // font-size: 37px;
            // letter-spacing: -1px;
            // line-height: 44px;
            margin-bottom: 0;
            padding-top: 6rem;
        }

        @include xs {
            font-size: 37px;
            letter-spacing: -1px;
            line-height: 44px;
            margin-bottom: 0;
            padding-top: 6rem;
        }

        
    }

    p {
        font-family: 'futura-pt',
        sans-serif;
        font-size: 22px;
        color: #4A4A4A;
        text-align: left;
        line-height: 40px;
        font-weight: 400;
        width: 90%;
        margin: 1rem 0;
        @include md {
            // font-size: 12px;
            text-align: left;
            // line-height: 25px;
            width: 100%;
        }

        @include xs {
            font-size: 14px;
            text-align: left;
            line-height: 25px;
            width: 100%;
        }

        
        
    }

    span.number {
        font-family: 'futura-pt',
        sans-serif;
        font-size: 42px;
        color: #FF5EE6;
        letter-spacing: 2.52px;
        line-height: 33px;
        font-weight: 300;
        margin-bottom: .5rem;

        @include xs {
            font-size: 28px;
            letter-spacing: 1.68px;
            line-height: 33px;
        }
        
    }

    h3 {
        font-family: 'futura-pt',
        sans-serif;
        font-size: 36px;
        color: #1E268B;
        letter-spacing: 2.16px;
        line-height: 46px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0;
        @include md {
            // font-size: 22px;
            // letter-spacing: 1.32px;
            // line-height: 29px;
            width: 65%;
        }

        @include xs {
            font-size: 22px;
            letter-spacing: 1.32px;
            line-height: 29px;
            width: 65%;
        }
        
    }

    a {

    margin-top: auto;

        &:hover {

            button {
                border: 1px solid #FF5EE6;
                background-color: #FF5EE6 !important;
                color: white;

            }

        }

        button {
            font-family: 'futura-pt',
                sans-serif;
            font-size: 12px;
            color: #1E268B;
            letter-spacing: 2.4px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 400;
            border: 1px solid #FF5EE6;
            padding: .75rem 2rem;
            transition: all .3s;
            width: 322px;
            // background: white !important;
            background-color: white !important;
            -moz-appearance: none !important;

            @include xl {
                width: 290px;
            }
                
            @include lg {
                width: 100%;
                text-align: center;
                background: white;
            }
    

            @include xs {
                font-size: 10px;
                letter-spacing: 2px;
                text-align: center;
                background: white;
                width: 100%;

            }
            
        }
    }
}

section#enroll {
    min-height: 225px;
    position: relative;
    width: 100%;
    background-image: url(/img/smoke.jpg);
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    
       @include xs {
          min-height: 219px;
          background-attachment: inherit;
       }


    .enroll-container {

        @include md {
            width: 80%;
            margin: 0 auto;

        }
        @include xs {
            width: 80%;
            margin: 0 auto;

        }
        


    }

    h2 {
        font-family: 'futura-pt',
        sans-serif;
        font-size: 36px;
        color: #FFFFFF;
        letter-spacing: 2.16px;
        text-align: center;
        line-height: 33px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 2rem;

        @include xs {
            font-size: 18px;
            letter-spacing: 1.08px;
            text-align: center;
            line-height: 22px;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 1.5rem;

        }


    }

    a {


        &:hover {

            button {
                // border: 1px solid #FF5EE6;
                background: #FF5EE6;
                color: white;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

            }

        }

        button {
            font-family: 'futura-pt',
                sans-serif;
            font-size: 12px;
            color: #1E268B;
            letter-spacing: 2.4px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 400;
            // border: 1px solid #FF5EE6;
            padding: 1.25rem 2rem;
            transition: all .3s;
            width: 286px;
            background: white;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
                border: 0;

            @include xs {

                font-size: 10px;
              
                letter-spacing: 2px;
                text-align: center;
                width: 226px;
                padding: .9rem 2rem;
            }

        }
    }

}


.header {
    background-color: #fff;
    // box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .1);
    position: fixed;
    width: 100%;
    z-index: 3;
    // width: 85%;
    // padding-right: 2rem;
    // padding-left: 
    margin: 0 auto;
    display: flex;
    // justify-content: space-around;
    align-items: center;
    @include xs {
        display: block;
    }

}
.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
}
/* menu */

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
    // cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 25px;
    position: relative;
    user-select: none;
    margin-top: 7px;
}

.header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}

/* menu btn */

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked~.menu {
    max-height: 240px;
    padding-bottom: 1rem;
}

.header .menu-btn:checked~.menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked~.menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.header .menu-btn:checked~.menu-icon .navicon:after {
    transform: rotate(45deg);
}

.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
    top: 0;
}

/* 48em = 768px */

@media (min-width: 500px) {

    .header {
        display: flex;
        justify-content: space-between;
    }
    .header li {
        // float: left;
    }

    .header li a {
        // padding: 20px 30px;
    }

    .header .menu {
        clear: none;
        float: right;
        max-height: none;
        margin-right: 5rem;

         @include md {
             margin-right: 2rem;

         }
    }

    .header .menu-icon {
        display: none;
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    section#enroll {
        height: 225px;
    }
   
}


.tfl-logo {
    width: 150px;
}